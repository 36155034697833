import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import OurPeopleHero from "../components/culture/our-people-hero"
import WellSourced from "../components/culture/well-sourced"
import TheTeams from "../components/culture/the-teams"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        cfCultureOurPeople {
          cultureOurPeopleHeroTitle
          cultureOurPeopleIntroParagraph
          cultureOurPeopleLhsTitle
          cultureOurPeopleStatDescription1
          cultureOurPeopleStatDescription2
          cultureOurPeopleStatDescription3
          cultureOurPeopleStatNumber1
          cultureOurPeopleStatNumber2
          cultureOurPeopleStatNumber3
        }
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
      staffPeople(
        first: 100
        where: { orderby: { field: MENU_ORDER, order: ASC } }
      ) {
        nodes {
          content
          title
          featuredImage {
            node {
              altText
              mimeType
              mediaDetails {
                file
              }
            }
          }
          people {
            peopleLinkedinUrl
            personPostion
            peopleDisplayInGroup
          }
        }
      }
    }
  }
`
const PageTemplateOurPeople = ({ data }) => {
  const page = data.wpgraphql.page
  const people = data.wpgraphql.staffPeople.nodes

  return (
    <Layout>
      <OurPeopleHero pagedata={page} />
      <WellSourced pagedata={page} />
      <TheTeams people={people} />
    </Layout>
  )
}

export default PageTemplateOurPeople

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
