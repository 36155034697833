import React from "react"
import { isSafariLess16 } from "../utils.js"
import * as s from "./our-people-hero.module.css"

const HomeHero = ({ pagedata }) => {
  const d = pagedata.cfCultureOurPeople
  const isSafariV16 = isSafariLess16()

  return (
    <div className="overflow-hidden">
      <div className="darkgrey_bg mb-6 pt-5 pt-lg-0">
        <div className="container">
          <div className="row">
            <div
              id="hide-row-when-nav-open"
              className="col-12 col-lg-6 d-flex align-items-center color-white animated-headline mt-5 mt-lg-0"
            >
              <div className="animated-headline-wrap">
                <h1 className="new_style">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: d.cultureOurPeopleHeroTitle,
                    }}
                    className={`new_style_addit ${
                      isSafariV16 ? "safari_16" : ""
                    }`}
                  />
                </h1>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-6">
              <div className="d-flex">
                <img
                  className={`${s.team_hero} header-fade-in`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/new-clare-hero_smlzyz.png"
                  alt="waracle employee"
                />
                <img
                  className={`${s.team_hero} header-fade-in`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/hero-tomo_skjvaz.png"
                  alt="waracle employee"
                  style={{
                    marginLeft: "-100px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
