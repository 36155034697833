import React from "react"

const WellSourced = ({ pagedata }) => {
  const d = pagedata.cfCultureOurPeople

  return (
    <div className="container mb-6">
      <div className="row">
        <div className="col-12 col-lg-3 mb-4">
          <h2 className="h3 lower">{d.cultureOurPeopleLhsTitle}</h2>
        </div>
        <div className="col-12 col-lg-9">
          <p
            dangerouslySetInnerHTML={{
              __html: d.cultureOurPeopleIntroParagraph,
            }}
            style={{
              fontSize: "18px",
              lineHeight: "30px",
            }}
          />
          <div className="d-flex flex-column flex-sm-row">
            {d.cultureOurPeopleStatNumber1 !== null && (
              <div className="col mt-100">
                <h2 className="big_number">
                  <span id="count">{d.cultureOurPeopleStatNumber1}</span>
                </h2>
                <h3 className="h4">{d.cultureOurPeopleStatDescription1}</h3>
              </div>
            )}
            {d.cultureOurPeopleStatNumber2 !== null && (
              <div className="col mt-100">
                <h2 className="big_number">
                  <span id="count2">{d.cultureOurPeopleStatNumber2}</span>
                </h2>
                <h3 className="h4">{d.cultureOurPeopleStatDescription2}</h3>
              </div>
            )}
            {d.cultureOurPeopleStatNumber3 !== null && (
              <div className="col mt-100">
                <h2 className="big_number">
                  <span id="count3">{d.cultureOurPeopleStatNumber3}</span>
                </h2>
                <h3 className="h4">{d.cultureOurPeopleStatDescription3}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WellSourced
