import React from "react"
import APerson from "./a-person"

const TheTeams = ({ people }) => {
  // set up data

  let level
  // const listLeaders =

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-5">
            <h3>Our Senior Leadership Team</h3>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h3 className="h4">Senior Leadership Team</h3>
          </div>
        </div>
        <div className="row">
          {people.map((person, index) => {
            level = 1
            return <APerson key={index} person={person} level={level} />
          })}
        </div>
      </div>
    </>
  )
}

export default TheTeams
