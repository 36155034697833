import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as personStyle from "./a-person.module.css"

const APerson = ({ person, level }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const slug = person.featuredImage.node.mediaDetails.file
  const altText = person.featuredImage.node.altText

  const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug}`)

  theImage.quality("70")
  theImage.resize(fill().width(600).height(800))
  theImage.format("auto")

  return (
    <>
      {level == person.people.peopleDisplayInGroup && (
        <div
          className={`col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3 mb-lg-5 ${personStyle.list_link}`}
        >
          <AdvancedImage
            cldImg={theImage}
            alt={altText}
            className={`mw-100 mb-1 mb-lg-3`}
            onClick={handleShow}
          />
          <h4 className="lower mb-0" onClick={handleShow}>
            {person.title}
          </h4>
          <h4 className="lower" onClick={handleShow}>
            {person.people.personPostion}
          </h4>
        </div>
      )}

      <Modal show={show} onHide={handleClose} size="xl">
        <button
          type="button"
          className="nav-btn position-absolute"
          onClick={handleClose}
        >
          <svg
            className="new-close-icon"
            width="14px"
            height="14px"
            viewBox="0 0 14 14"
          >
            <g
              id="close-icon"
              transform="translate(-5.000000, -5.000000)"
              fill="#000000"
              fillRule="nonzero"
            >
              <path
                d="M18.3,5.71 C18.1131554,5.52277191 17.8595095,5.41755597 17.595,5.41755597 C17.3304905,5.41755597 17.0768446,5.52277191 16.89,5.71 L12,10.59 L7.11,5.7 C6.85812776,5.44812775 6.49101597,5.34976044 6.14695208,5.44195208 C5.8028882,5.53414372 5.53414372,5.8028882 5.44195208,6.14695208 C5.34976044,6.49101597 5.44812775,6.85812776 5.7,7.11 L10.59,12 L5.7,16.89 C5.44812776,17.1418722 5.34976045,17.508984 5.44195209,17.8530479 C5.53414373,18.1971118 5.80288821,18.4658563 6.14695209,18.5580479 C6.49101597,18.6502395 6.85812776,18.5518722 7.11,18.3 L12,13.41 L16.89,18.3 C17.1418722,18.5518722 17.508984,18.6502395 17.8530479,18.5580479 C18.1971118,18.4658563 18.4658563,18.1971118 18.5580479,17.8530479 C18.6502395,17.508984 18.5518722,17.1418722 18.3,16.89 L13.41,12 L18.3,7.11 C18.68,6.73 18.68,6.09 18.3,5.71 Z"
                id="Path"
              ></path>
            </g>
          </svg>
        </button>
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-12 col-lg-6">
            <AdvancedImage
              cldImg={theImage}
              alt={altText}
              className={`mw-100`}
            />
          </div>
          <div className="col-12 col-lg-6 p-3 p-lg-4 p-xl-5">
            <h2>{person.title}</h2>
            <h3 className="lower mb-4">{person.people.personPostion}</h3>
            <div dangerouslySetInnerHTML={{ __html: person.content }} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default APerson
